/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* General Styles */

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

body {
  width: 100wh;
  background: linear-gradient(-45deg, #022b3a, #1f7a8c, #bfdbf7, #e1e5f2);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}
  
@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

h1 {
  font-size: 2.4rem;
  padding-bottom: 2%;
}

h2, h3 {
  font-size: 1.8rem;
}

h4, p {
  font-size: 1.4rem;
}

img {
  height: auto;
  width: 100%;
}

section {
  margin-bottom: 4%;
}

.App {
  font-family: sans-serif;
}

.tab {
  margin: 0 4%;
}

.navigation {
  background-color: white;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4%;
  border-bottom: 1px solid rgb(221, 221, 221);
  -webkit-box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.45);
}

.icon {
  margin-right: 4%;
}

.icons {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  font-size: 1.2rem;
}

.links {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  font-size: 1.8rem;
}

.link, .icon {
  text-decoration: none;
  color: black;  
  padding-left: 8%; 
}

.link:hover, .icon:hover {
  color: #C70039;  
}

.navDrawerMenu {
  visibility: hidden;
}

#home {
  margin-top: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImage {
  border-radius: 150px;
  width: 80%;
}

.homeContent {
  width: 40%;
}

@media(max-width: 500px) {
  #home {
    flex-direction: column;
    margin-top: 8%;
  }
  .profileImage {
    width: 60%;
    margin-bottom: 4%;
  }
  .profImageContainer {
    width: 50%;
    text-align: center;
  }
  .homeContent {
    width: 80%;
    text-align: center;
  }
}

#aboutMe {
  padding: 4%;
  width: 50%;
}

/* .resume {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 50px;
  display: flex;
}

.leftSide {
  width: 20%;
  margin-right: 4%;
}
.rightSide {
  width: 80%;
} */

.experience {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 20% 5% 0 5%;
  padding: 2% 1%;
  border-radius: 5px;
  background-color: white;
  border-bottom: 1px solid rgb(221, 221, 221);
  -webkit-box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.45);
}

.projectList {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 10%;
}
@media(max-width: 500px) {
  .projectList {
    padding-bottom: 20%;
  }
}

.project {
  padding: 2%;
}

.experience {
  margin-bottom: 10%;
}

.skillsContainer {
  padding-bottom: 2%;
}
.skillsContainer:nth-last-child(1) {
  padding-bottom: 0;
}

.experienceCard {
  padding-bottom: 1%;

}
.experienceCard:nth-last-child(1) {
  padding-bottom: 0;
}

.educationList {
  margin-bottom: 10%;
}

.footer {
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: 0 4%;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid rgb(221, 221, 221);
  -webkit-box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.45);
  box-shadow: 0px 2px 15px -8px rgba(0, 0, 0, 0.45);
}

/* tablet */
@media only screen and (max-width: 800px) {
  .navDrawerMenu {
    visibility: hidden;
  }
}

/* mobile */
@media only screen and (max-width: 500px) {
  .links {
    display: none;
  }

  .navDrawerMenu {
    visibility: visible;
  }
}